window.addEventListener("DOMContentLoaded", function () {
  const logo = document.getElementById("logo");
  logo.addEventListener("click", function () {
    location.reload();
  });

  // Ouvrir la pop-up
  var openPopUp = function () {
    document.querySelector(".pop-up").classList.toggle("is-active");
  };
  document
    .querySelector(".js-pop-up-target")
    .addEventListener("click", openPopUp);

  // Fermer la pop-up
  var closePopUp = function () {
    document.querySelector(".pop-up").classList.remove("is-active");
  };
  document.querySelector(".close-button").addEventListener("click", closePopUp);

  // Masquer la pop-up
  var hidePopUp = function () {
    document.querySelector(".js-pop-up-target").classList.add("is-hidden");
  };
  document
    .querySelector(".js-pop-up-target")
    .addEventListener("click", hidePopUp);

  // Réafficher la pop-up
  var showPopUp = function () {
    document.querySelector(".js-pop-up-target").classList.remove("is-hidden");
  };
  document.querySelector(".close-button").addEventListener("click", showPopUp);

  //aleatoire//

  // console.log(nextButtons);

  //cibler le button come this way
  var target = document.querySelector(".intro-button");

  //cibler toute la page d'accueil + ajout de classe
  var addHidden = function () {
    document.querySelector(".intro").classList.add("is-hidden");
    console.log("help");
  };

  target.addEventListener("click", addHidden);

  // ============================

  var currentNumber = 0;
  var videoQty = 12;

  function pickANumber(maxValue) {
    var randomNumber = Math.ceil(Math.random() * maxValue);
    return randomNumber;
  }

  function selectVideo() {
    var newVideoNumber = pickANumber(videoQty);
    //  console.log('tirage : ' + newVideoNumber + '/' + currentNumber);
    while (newVideoNumber === currentNumber) {
      //  console.log('nombre identique : ' + newVideoNumber + '/' + currentNumber);
      newVideoNumber = pickANumber(videoQty);
    }

    currentNumber = newVideoNumber;

    var videoElement = document.querySelector("#videoElement");

    var videoSource = document.querySelector(".videoSource");

    videoSource.setAttribute("src", `video-${currentNumber}.mp4`);
    videoElement.removeAttribute("muted");

    // videoElement.muted = false;

    videoElement.load();
    videoElement.play();
    videoElement.volume = 1;

    // ajouter le code d'intégration à la source vidéo
    // --> remplacer l'attribut source de vidéo
    // 'video-'+currentNumber+'.mp4';
  }

  //if (document.querySelector('body.is-homepage')) {
  // if (document.querySelector(".next.launch-button")) {
  //   launchButtonArray = document.querySelectorAll(".launch-button");
  //   launchButtonArray.forEach((launchButton) => {
  //     launchButton.addEventListener("click", function () {
  //       selectVideo();
  //     });
  //   });
  // }
  var launchButton = document.querySelector(".launch-button");

  if (launchButton) {
    launchButton.addEventListener("click", function () {
      selectVideo();
    });
  }

  //cibler le button come this way
  var introButton = document.querySelector(".intro-button");

  //cibler toute la page d'accueil + ajout de classe
  if (introButton) {
    introButton.addEventListener("click", function () {
      document.querySelector(".accueil").classList.add("is-hidden");
      document
        .querySelector(".next.launch-button")
        .classList.remove("is-hidden");
      selectVideo();
    });
  }
});
